<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" :aria-sort="this.ariaSort('name')">Project name <button type="button" @click="$store.commit('projects/SORT_PROJECTS', 'name');"><inline-svg :src="require(`../assets/svg/icon-arrow-neutral.svg`)" /></button></th>
                    <th scope="col" :aria-sort="this.ariaSort('created_by')">Project created by <button type="button" @click="$store.commit('projects/SORT_PROJECTS', 'created_by');"><inline-svg :src="require(`../assets/svg/icon-arrow-neutral.svg`)" /></button></th>
                    <th scope="col" :aria-sort="this.ariaSort('created_on')">Project created on <button type="button" @click="$store.commit('projects/SORT_PROJECTS', 'created_on');"><inline-svg :src="require(`../assets/svg/icon-arrow-neutral.svg`)" /></button></th>
                    <th scope="col" :aria-sort="this.ariaSort('last_updated')">Last updated on <button type="button" @click="$store.commit('projects/SORT_PROJECTS', 'last_updated');"><inline-svg :src="require(`../assets/svg/icon-arrow-neutral.svg`)" /></button></th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(project) in projects" :key="project.name">
                    <th scope="row">
                        <h4>{{ project.name }}</h4>
                        <span>{{ personasCount(project.name) }} persona<span v-if="personasCount(project.name) > 1">s</span></span>
                    </th>
                    <td>{{ project.created_by }}</td>
                    <td>{{ moment(project.created_on) }}</td>
                    <td>
                        <span v-if="personasCount(project.name) < 1">{{ moment(project.created_on) }}</span>
                        <span v-else>{{ moment(project.last_updated) }}</span>
                    </td>
                    <td>
                        <div class="d-flex">
                            <div class="share-btn" @click="this.projectName = project.name; this.projectId = project.id">
                                <Button text="Share" variant="outline-secondary" modalId="shareModal" :modalProjectName="project.name" :modalPersonasTotal="personasCount(project.name)" />
                            </div>
                            <Button text="View" iconPosition="right" icon="icon-arrow-right" :to="`/${project.name}`" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <Modal id="shareModal" title="Share your project" :subtitle="`${projectName} (${personasCount(projectName)} personas)`">
        <form>
            <div class="form-group mt-40 mb-40">
                <label for="passwordProtectInput" class="form-label">Password protect</label>
                <div class="d-flex align-items-center">
                    <input type="text" class="form-control form-control--sm mr-16 savePasswordInput" id="passwordProtectInput" aria-describedby="passwordProtect" v-model="pvtData">
                    <button type="button" id="savePasswordBtn" class="btn btn-primary" @click="encryptData(projectId, 'savePasswordBtn')">Save</button>
                </div>
            </div>
            <div class="form-group pb-16">
                <label for="copyUrlInput" class="form-label">Copy this private URL to share</label>
                <div class="d-flex align-items-center">
                    <input type="text" class="form-control mr-16 copyUrlInput" id="copyUrlInput" aria-describedby="copyUrl" :value="`${this.protocol}//${this.hostname}${getPort()}/share/${projectName}/auth`">
                    <button type="button" id="copyBtn" class="btn btn-secondary copy-link" :data-clipboard-text="`${this.protocol}//${this.hostname}${getPort()}/share/${projectName}/auth`" @click="copyUrl($event)">Copy</button>
                    <span id="shareModal__success-msg" class="success-msg">Copied to clipboard</span>
                </div>
            </div>
        </form>
    </Modal>
</template>

<script>
import moment from 'moment';
import InlineSvg from 'vue-inline-svg';
import { mapActions, mapGetters, mapState } from 'vuex';
import Button from '../components/Button.vue';
import Modal from '../components/Modal.vue';

export default {
    name: 'Table',

    components: {
        Button,
        InlineSvg,
        Modal,
    },

    props: {
        modalId: {
            type: String,
            required: false,
            default: '',
        },
    },

    data() {
        return {
            pvtData: "",
            secret: "123#$%",
            encData: "",

            projectId: '',
            projectName: '',
        }
    },

    computed: {
        ...mapGetters({
            personasCount: 'personas/countPersonaByProject',
            projectsFiles: 'projects/sortedProjects',
        }),

        ...mapState('projects', ['current_sort_type', 'current_sort_ascending']),

        personas() {
            return this.personasCount
        },
        projects() {
            return this.projectsFiles
        },

        hostname() {
            return  window.location.hostname
        },
        protocol() {
            return window.location.protocol
        },
    },

    methods: {
        ...mapActions('projects', {
            savePassword: 'savePassword',
            sortProjects: 'sortProjects',
        }),

        sorting(sortType) {
            let payload = { 'sortType': sortType };
            this.sortProjects(payload);
        },
        ariaSort(sortType) {
            let value = '';
            if(this.current_sort_type == sortType && this.current_sort_ascending) {
                value = 'ascending';
            } else if(this.current_sort_type == sortType && !this.current_sort_ascending) {
                value = 'descending';
            } else {
                value = 'none'
            }
            return value
        },

        iteration: function(index) {
            return index + 1
        },

        copyUrl(event) { // id
            const copyText = event.target.getAttribute('data-clipboard-text');
            const successMsg = document.getElementById(`shareModal__success-msg`);
            const copyBtn = document.getElementById(`copyBtn`);
            const thisModal = document.getElementById(`shareModal`);

            this.$copyText(copyText, thisModal).then(function (e) {
                console.log(e);
                successMsg.classList.add('show');
                copyBtn.innerHTML = '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0 .3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-11.00000002 11.00000002c-.36048396.3604839-.92771502.3882135-1.32000622.0831886l-.09420734-.0831886-5-5c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136.36048396-.3604839.92771502-.3882135 1.32000622-.0831886l.09420734.0831886 4.29289322 4.2921068z" fill="#242831"/></svg>';
                setTimeout(function(){
                    successMsg.classList.remove('show');
                    copyBtn.innerHTML = 'Copy';
                }, 3000);
            }, function (e) {
                alert('Can not copy')
                console.log(e)
            })
        },

        encryptData(projectId, id) {
            var CryptoJS = require("crypto-js");
            if (this.pvtData.length) {
                // hash the name with any algorithm
                const data = CryptoJS.AES.encrypt(this.pvtData, this.secret).toString();

                // Save password in firebase doc
                let payload = { 'projectId': projectId, 'newPassword': data, 'id': id, };
                this.savePassword(payload);
            }
        },

        getPort() {
            if(window.location.port) {
                return ':' + window.location.port
            } else {
                return '';
            }
        },

        moment: function (date) {
            return moment(date).format("LL");
        }
    }
}
</script>
