<template>
    <div class="p-home container-xl">
        <PageHeader title="All projects" :text="`${projectsCountTotal} projects, ${personasCountTotal} personas`">
            <Button text="Create new project" variant="outline-secondary" modalId="createProjectModal" />
            <Button :to="{name: 'NewPersona'}" text="Add persona" variant="secondary" iconPosition="left" icon="icon-plus" />
        </PageHeader>
        <div class="mb-5">
            <Table modalId="shareProjectModal" />
        </div>
        <Modal id="createProjectModal" title="Create a new project" subtitle="This project will be visible for everyone at Blache, Yong & Co.">
            <form id="create-project-form" @submit.prevent="createProject">
                <div class="form-group mt-40 mb-40">
                    <label for="projectNameInput" class="form-label">Project name</label>
                    <input type="text" name="name" class="form-control" id="projectNameInput" aria-describedby="projectName">
                </div>
                <button type="submit" class="btn btn-secondary">Save project</button>
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
            </form>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '../components/Button.vue';
import Modal from '../components/Modal.vue';
import PageHeader from '../components/PageHeader.vue';
import Table from '../components/Table.vue';

export default {
    name: 'Home',

    components: {
        Button,
        Modal,
        PageHeader,
        Table,
    },

    methods: {
        ...mapActions('projects', {
            createProject: 'createProject',
            fetchPersonas: 'fetchPersonas',
        }),
    },

    computed: {
        ...mapGetters({
            personasCount: 'personas/countPersonaByProject',
            projectsTotal: 'projects/projectsTotal',
            personasTotal: 'personas/personasTotal',
        }),
        personasCountProject() {
            return this.personasCount
        },
        personasCountTotal() {
            return this.personasTotal
        },
        projectsCountTotal() {
            return this.projectsTotal
        },
    },

    created() {
        this.$store.dispatch('personas/fetchPersonas');
    },
}
</script>
